import React from 'react';
import PropTypes from 'prop-types';

import MapFilterControl from './MapFilterControl';

import { Icon } from '../ui';
import { FilterControlsContainer as Container, UseMyLocationButton } from './MapFilterControls.style';

import { getFeatureFlag, FEATURE_FLAGS } from '../../config/featureFlags';

import LocationTarget from '../../static/images/icons/LocationTarget.svg';
import LocationTargetOutline from '../../static/images/icons/LocationTargetOutline.svg';

import getCampsiteType from '../../lib/campsiteTypes';
import { ids as campsiteTypeIds } from '../../config/campsiteTypes';
import { USER_LOCATION_PERMISSIONS } from '../../lib/location';
import withResizeObserver from '../../hocs/withResizeObserver';
import { fixedInfoWindowClass } from '../CampsiteMap/FixedInfoWindow.style';

const MapFilterControls = ({
  campsiteTypes,
  selectedTypes,
  onFiltersChange,
  isTours,
  onLocationButtonClick,
  userLocationIsVisible,
  locationPermission,
  isOverseas,
  hideMyLocationButton,
  resizeAdjustmentAmount, // from withResizeObserver HOC
}) => {
  if (!getFeatureFlag(FEATURE_FLAGS.UK_SITES)) {
    return null;
  }
  const locationPermissionsAccepted = locationPermission === USER_LOCATION_PERMISSIONS.ACCEPTED;
  // We only show the target missing it's center when we've accepted permissions and the user's
  // location is not visible on the screen
  const shouldShowAlternateIcon = locationPermissionsAccepted && !userLocationIsVisible;
  return (
    <Container isBottom={!!isTours} resizeAdjustmentAmount={resizeAdjustmentAmount} id="mapFilterControls">
      {!isOverseas &&
        <MapFilterControl
          active={selectedTypes.includes(campsiteTypeIds.CERTIFICATED_LOCATIONS)}
          campsiteType={
            getCampsiteType(campsiteTypes, campsiteTypeIds.CERTIFICATED_LOCATIONS)
          }
          onFiltersChange={onFiltersChange}
        />
      }
      {!hideMyLocationButton &&
        <UseMyLocationButton
          isOverseas={isOverseas}
          havePermission={locationPermissionsAccepted}
          onClick={onLocationButtonClick}
        >
          <Icon icon={shouldShowAlternateIcon ? LocationTargetOutline : LocationTarget} size="2rem" />
        </UseMyLocationButton>
      }
    </Container>
  );
};

MapFilterControls.propTypes = {
  campsiteTypes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  selectedTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
  isTours: PropTypes.bool,
  userLocationIsVisible: PropTypes.bool,
  onLocationButtonClick: PropTypes.func,
  locationPermission: PropTypes.string,
  isOverseas: PropTypes.bool,
  hideMyLocationButton: PropTypes.bool,
  resizeAdjustmentAmount: PropTypes.number.isRequired, // from withResizeObserver HOC
};

MapFilterControls.defaultProps = {
  isTours: false,
  userLocationIsVisible: false,
  onLocationButtonClick: () => { },
  locationPermission: USER_LOCATION_PERMISSIONS.NOT_KNOWN,
  isOverseas: false,
  hideMyLocationButton: false,
};

export default withResizeObserver(MapFilterControls, fixedInfoWindowClass, 50);
