import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Marker, OverlayView, OverlayViewF } from '@react-google-maps/api';
import { util } from 'node-forge';

import InfoWindow from './InfoWindow';

import svgCrossingMarker from '../../../static/images/icons/CrossingMarker.svg';
import { MARKER_Z_INDEX } from '../../CampsiteMap/constants';

export const createBase64 = svg => `data:image/svg+xml;charset=UTF-8;base64,${util.encode64(svg)}`;

function Markers({
  activePort, onClick, ports, showMarker,
}) {
  const handleClick = useCallback(id => onClick(id), [onClick]);

  const icon = {
    size: new window.google.maps.Size(12, 12),
    scaledSize: new window.google.maps.Size(12, 12),
    url: createBase64(svgCrossingMarker),
  };

  return ports.map((port) => {
    const position = {
      lat: port.lat,
      lng: port.lon,
    };

    const markerIsActive = port.name === activePort.portName && showMarker;

    const getPixelPositionOffset = (width, height) => ({
      x: -(width / 2),
      y: -height - 16,
    });

    return (
      <Marker
        icon={icon}
        key={port.name}
        noRedraw
        position={position}
        onClick={() => handleClick(port.name)}
        zIndex={MARKER_Z_INDEX.CAMPSITE_MARKER}
        options={{
          optimized: false,
        }}
      >
        {markerIsActive &&
          <OverlayViewF
            getPixelPositionOffset={getPixelPositionOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{
              lat: port.lat,
              lng: port.lon,
            }}
          >
            <InfoWindow text={port.name} />
          </OverlayViewF>
        }
      </Marker>
    );
  });
}

Markers.propTypes = {
  ports: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
};

Markers.defaultProps = {
  ports: [],
  onClick: () => null,
};

export default Markers;
