import React, { Component } from 'react';

import { GoogleContext } from '../context/GoogleProvider';

export default function WithGoogle(WrappedComponent) {
  return class extends Component {
    static contextType = GoogleContext;

    render() {
      return (
        <WrappedComponent {...this.context} {...this.props} />
      );
    }
  };
}
