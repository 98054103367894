import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { dictionaryItem } from '../../hocs/withDictionary';
import { below } from '../../styles/libs';
import { MAP_FOOTER_TIGHT_GUTTER_BREAKPOINT } from './Map.style';
import Checkbox from '../ui/Form/Checkbox/Checkbox';
import CHECKBOX_VARIANTS from '../ui/Form/Checkbox/consts';
import { Title } from '../ui';

const MapCheckboxWrapper = styled.div`
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
  background-color: ${({ theme }) => (theme.COLOR_WHITE)};
  color: ${({ theme }) => (theme.COLOR_CYAN_DARK)};
  border: ${({ theme }) => (`2px solid ${theme.COLOR_CYAN_DARK}`)};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;


  ${({ theme }) => below(theme.MOBILE_LARGE)`
    // width: 100%;
  `}
  ${() => below(MAP_FOOTER_TIGHT_GUTTER_BREAKPOINT)`
    margin-top: 0.25rem;
    margin-right: 0.25rem;
  `}
`;

const MapCheckboxFormGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

`;

const MapCheckboxTitle = styled(Title)`
  margin-left: 10px;
  cursor: pointer;
  color: ${({ theme }) => (theme.COLOR_CYAN_DARK)};
  font-family: 'ElliotBold', sans-serif;
  font-size: 20px;
`;

const MapFilterControl = ({ campsiteType, active, onFiltersChange }) => {
  const toggleCheckbox = (e) => {
    if (e.target.type !== 'checkbox') {
      onFiltersChange(campsiteType.id, !active);
    }
  };

  const checkboxId = `${campsiteType.text} - ${campsiteType.id}`;

  return (
    <MapCheckboxWrapper onClick={toggleCheckbox}>
      <MapCheckboxFormGroup htmlFor={checkboxId}>
        <Checkbox
          name={`${campsiteType.text} - ${campsiteType.id}`}
          checked={active}
          onChange={toggleCheckbox}
          variant={CHECKBOX_VARIANTS.FILLED}
          id={checkboxId}
        />
        <MapCheckboxTitle
          dictionary={dictionaryItem('CampsiteSearch', 'MapCLS')}
          tag={4}
        />
      </MapCheckboxFormGroup>
    </MapCheckboxWrapper>
  );
};

MapFilterControl.propTypes = {
  campsiteType: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default MapFilterControl;
