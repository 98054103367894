import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  message: PropTypes.string,
};

const ErrorMessage = props => (
  <Fragment>
    {props.message}
  </Fragment>
);

ErrorMessage.propTypes = propTypes;

ErrorMessage.defaultProps = {
  message: 'Error when fetching data. Please reload page and try again.',
};

export default ErrorMessage;
