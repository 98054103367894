import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../../../styles/config/theme';

export const Wrapper = styled.div`
  background-color: ${theme.COLOR_BRAND_PRIMARY};
  border-radius: 3px;
  color: ${theme.COLOR_WHITE};
  padding: 0.25rem 0.5rem;
`;

function InfoWindow({ text }) {
  return (
    <Wrapper>{text}</Wrapper>
  );
}

InfoWindow.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InfoWindow;
