import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Polyline } from '@react-google-maps/api';

function RouteLine({
  active,
  color,
  onClick,
  path,
  route,
}) {
  const handleClick = useCallback(() => onClick(route), [onClick, route]);

  // Configure symbol for dotted line
  const lineSymbol = {
    path: google.maps.SymbolPath.CIRCLE,
    fillOpacity: 1,
    scale: 1.5,
  };

  // Configure active route line display
  const pattern = !active ? {
    icons: [{
      icon: lineSymbol,
      offset: '0',
      repeat: '8px',
    }],
    strokeOpacity: 0,
  } : {};

  // Configure route line display
  const options = {
    strokeColor: color,
    strokeOpacity: 1,
    strokeWeight: 4.5,
    ...pattern,
  };

  return (
    <Polyline onClick={handleClick} options={options} path={path} />
  );
}

RouteLine.propTypes = {
  active: PropTypes.bool.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  path: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  route: PropTypes.shape({}).isRequired,
};

RouteLine.defaultProps = {
  color: '',
  onClick: () => null,
};

export default RouteLine;
