import styled from 'styled-components';
import { below } from '../../styles/libs';
// Components
import ErrorMessage from '../ErrorMessage';

// Needed for reuse in resizing other elements in HOC
const fixedInfoWindowClassName = 'fixed-info-window';
export const fixedInfoWindowClass = `.${fixedInfoWindowClassName}`;

export default styled.section.attrs({
  className: fixedInfoWindowClassName,
})`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  pointer-events: auto;

  ${({ theme }) => below(theme.TABLET)`
    position: absolute;
    bottom: 0;
    width: 100%;
  `}
`;

export const LoadingAnimation = styled.section`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  text-align: center;
  padding: 0.5rem;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  display: none !important;
`;
