import styled, { css } from 'styled-components';
import { above, below } from '../../styles/libs';
import { MAP_FOOTER_SINGLE_ROW_BREAKPOINT, MAP_FOOTER_TIGHT_GUTTER_BREAKPOINT } from './Map.style';

export const FilterControlsContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - 2rem);
  bottom: 3rem;
  left: 1rem;
  z-index: 1;

  ${({ theme }) => below(theme.DESKTOP)`
    flex-wrap: wrap;
  `}

  ${({ theme }) => below(theme.DESKTOP_LARGE)`
    width: 100%;
  `}

  ${({ theme, isBottom }) => !isBottom && above(theme.DESKTOP)`
    bottom: 1rem;
  `}

${({ theme, resizeAdjustmentAmount }) => below(theme.TABLET)`
    bottom: 1rem;
    margin-bottom: ${resizeAdjustmentAmount}px;
  `}

  ${() => above(parseInt(MAP_FOOTER_SINGLE_ROW_BREAKPOINT, 10) * 16)`
    bottom: 1rem;
  `}

  ${() => below(MAP_FOOTER_TIGHT_GUTTER_BREAKPOINT)`
    width: calc(100% - 0.75rem);
  `}
`;

export const UseMyLocationButton = styled.button`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: ${({ theme }) => `2px solid ${theme.COLOR_CYAN_DARK}`};
  color: ${({ theme }) => theme.COLOR_CYAN_DARK};
  padding: 0 0.5rem;
  border-radius: 4px;
  line-height: 0;
  cursor: pointer;

  ${({ isOverseas }) => isOverseas && css`
    margin-left: auto;
    padding: 0.5rem;
  `}

  ${({ havePermission }) => !havePermission && css`
    opacity: 0.7;
  `}
`;
