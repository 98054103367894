import React from 'react';
import { debounce } from 'lodash';

/**
 * A higher-order component to provide dynamic top positioning capabilities based on the dimensions
 * of an observed global element identified by a CSS selector.
 * Useable in both class and functional components.
 * Uses ResizeObserver to adjust positioning dynamically and MutationObserver to detect when
 * the targeted element is added to or removed from the DOM.
 * Use sparingly if CSS solution is not available due to architecture.
 *
 * @param {React.ComponentType} WrappedComponent - The component to be enhanced by the HOC.
 * @param {string} selector - The CSS selector for the element to be observed.
 * @param {string | undefined} contentRectProperty - Optional property of the contentRect to observe
 * ('top', 'bottom', 'left', 'right', 'width', 'height').
 * @param {number | undefined} debounceTime - debounce time in ms, default is 200ms.
 * @returns {React.ComponentType} component that passes the dynamic position to WrappedComponent.
 */
const withResizeObserver = (WrappedComponent, selector, debounceTime = 200, contentRectProperty = 'height') => {
  const validProperties = ['top', 'bottom', 'left', 'right', 'width', 'height'];

  class WithResizeObserver extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = { resizeAdjustmentAmount: 0 };
      this.resizeObserver = null;
      this.mutationObserver = null;
      this.debouncedHandleResize = debounce(this.handleResize.bind(this), debounceTime);
    }

    componentDidMount() {
      if (validProperties.includes(contentRectProperty)) {
        this.setupObservers();
      } else {
        console.error(`Invalid property: ${contentRectProperty}. Must be one of ${validProperties.join(', ')}.`);
      }
    }

    componentWillUnmount() {
      this.detachObservers();
    }

    handleResize(value) {
      this.setState({ resizeAdjustmentAmount: value });
    }

    setupObservers() {
      this.setupResizeObserver();

      // Setup MutationObserver to detect changes in the presence of the target element
      this.mutationObserver = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.addedNodes.length || mutation.removedNodes.length) {
            this.setupResizeObserver(); // Re-check and re-attach ResizeObserver if necessary
          }
        });
      });

      // Observe the entire document
      this.mutationObserver.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }

    setupResizeObserver() {
      const element = document.querySelector(selector);
      if (element) {
        if (!this.resizeObserver) {
          this.resizeObserver = new ResizeObserver(([entry]) => {
            if (entry) {
              this.debouncedHandleResize(entry.contentRect[contentRectProperty]);
            }
          });
        }
        this.resizeObserver.observe(element);
      } else {
        if (this.resizeObserver) {
          this.resizeObserver.disconnect();
          this.resizeObserver = null;
        }
        this.setState({ resizeAdjustmentAmount: 0 }); // Reset to default if element is not present
      }
    }

    detachObservers() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
      }
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
        this.mutationObserver = null;
      }
      this.debouncedHandleResize.cancel();
    }

    render() {
      return <WrappedComponent
        {...this.props}
        resizeAdjustmentAmount={this.state.resizeAdjustmentAmount}
      />;
    }
  }

  return WithResizeObserver;
};

export default withResizeObserver;
