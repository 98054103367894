/* eslint-disable import/prefer-default-export */
export const MARKER_Z_INDEX = {
  CAMPSITE_MARKER_NO_PRICE: 1,
  USER_LOCATION: 2,
  SEARCH_LOCATION: 3,
  POI_MARKER: 4,
  CAMPSITE_MARKER: 5,
};

export const GOOGLE_MAP_TILE_SIZE_PX = 256;
