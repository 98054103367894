import React, { memo } from 'react';
import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import RouteLine from './RouteLine';
import { returnPortName } from '../../../lib/helpers/crossings';

function RouteLines({
  activePorts,
  activeRoutes,
  onClick,
  routes,
  ports,
  theme,
}) {
  return routes.map((route) => {
    const { arrivalPortLocation, departurePortLocation, routeLine } = route;

    if (!departurePortLocation || !arrivalPortLocation || !routeLine.length) {
      return null;
    }

    const routePorts = [route.departurePortName, route.arrivalPortName];

    // check if routePorts are active ports
    const portIsActive =
      routePorts.includes(activePorts.outbound.portName) ||
      routePorts.includes(activePorts.inbound.portName);

    const routeIsActive = activeRoutes.map(({ id }) => id).includes(route.id);

    let routeLineIsActive = (!activeRoutes[0] && portIsActive) || routeIsActive;

    const routeArrivalPortName = returnPortName(ports, route.arrivalPort);
    const activeInboundPortName = returnPortName(ports, activePorts.inbound.portCode);

    // Overide inbound route lines
    const routeIsInbound =
      !activeRoutes[1] &&
      routeArrivalPortName === activeInboundPortName &&
      route.arrivalPortZone === activePorts.inbound.portZone;

    if (routeIsInbound) {
      routeLineIsActive = true;
    }

    // Merge port lat/lngs with route line lat/lngs
    const path = [
      {
        lat: departurePortLocation.lat,
        lng: departurePortLocation.lon,
      },
      ...routeLine.map(({ lat, lon }) => ({
        lat,
        lng: lon,
      })),
      { lat: arrivalPortLocation.lat, lng: arrivalPortLocation.lon },
    ];

    return (
      <RouteLine
        color={theme.COLOR_BRAND_PRIMARY}
        active={routeLineIsActive}
        key={route.id}
        onClick={onClick}
        path={path}
        route={route}
      />
    );
  });
}

RouteLines.propTypes = {
  onClick: PropTypes.func,
  ports: PropTypes.arrayOf(PropTypes.shape({})),
  routes: PropTypes.arrayOf(PropTypes.shape({})),
  theme: PropTypes.shape({}),
};

RouteLines.defaultProps = {
  activePorts: {
    outbound: null,
    inbound: null,
  },
  activeRoutes: [],
  ports: [],
  routes: [],
  theme: {},
  onClick: () => null,
};

export default compose(
  memo,
  withTheme,
)(RouteLines);
